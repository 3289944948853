import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  ${tw`grid grid-cols-12 bg-deep-blue text-moon-sand`}

  h2 > span {
    ${tw`italic`}
  }
`;

export const Heading = styled.h2`
  ${tw`col-start-2 col-span-10 text-center`}
  ${props => props.theme.fonts.headline_1}
  font-size: 3rem;

  ${media.lg`
    ${props => props.theme.fonts.headline_1}
  `}
`;

export const GoogleReviewPercent = styled.div`
  ${tw`col-start-1 col-span-6 lg:col-start-5 lg:col-span-2 grid grid-cols-12 text-center`}

  div {
    ${tw`col-start-1 col-span-12 lg:mb-1 inline-block`}
    ${props => props.theme.fonts.headline_3}
    font-size: 2.75rem;
  }

  p {
    ${tw`col-start-1 col-span-12 m-0`}
    font-size: 0.6rem;
    line-height: 0.66rem;
  }

  > p > span {
    ${props => props.theme.fonts.body}
    ${tw`font-bold`}
    font-size: 0.75rem;
  }
`;

export const GoogleReviewLogo = styled.div`
  ${tw`col-start-7 col-span-5 lg:col-span-2 self-center`}
`;

export const ReviewsWrapper = styled.div`
  ${tw`col-start-2 col-span-10 lg:col-start-3 lg:col-span-8 xxxl:col-start-4 xxxl:col-span-6 grid grid-cols-9 lg:gap-4 mt-12 lg:mt-20 overflow-hidden`}

  max-height: 900px;

  ${media.xxl`
    max-height: 700px;
  `}
`;

export const Col_1 = styled.div`
  ${tw`col-start-1 col-span-9 lg:col-start-1 lg:col-span-3`}
`;

export const Col_2 = styled.div`
  ${tw`col-start-1 col-span-9 lg:col-start-4 lg:col-span-3`}
`;

export const Col_3 = styled.div`
  ${tw`col-start-1 col-span-9 lg:col-start-7 lg:col-span-3`}
`;

export const Review = styled.section`
  ${tw`bg-sky-blue text-night-sky p-4 mb-4 rounded-xl`}

  h2 {
    ${props => props.theme.fonts.review_name}
    ${tw`m-0`}
  }

  p {
    ${props => props.theme.fonts.body}
    ${tw`mb-0`}
  }
`;

export const Stars = styled.ul`
  ${tw`list-none p-0 overflow-hidden mt-1 mb-2`}

  li {
    ${tw`float-left mr-1`}
    img {
      ${tw`w-3.5`}
    }
  }
`;

const OkIcon = require('./icons/ok.svg');
export const Verified = styled.div`
  ${props => props.theme.fonts.review_verified}
  ${tw`uppercase pl-6`}
  background: transparent url(${OkIcon}) center left no-repeat;
  background-size: 16px 16px;
`;

export const LinkWrapper = styled.div`
  ${tw`col-start-1 col-span-12 text-center mb-5 lg:mb-10`}

  box-shadow: 0 -50px 30px 20px ${props => props.theme.colors['deep-blue']};
`;
