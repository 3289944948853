import React, { memo, useState, useEffect } from 'react';
import { PoweredBy } from '@app/components/PoweredBy';
import { TestimonialRedesign } from '@app/components/TestimonialRedesign';
import {
  Hero,
  Creation,
  Occasions,
  Testimonial,
  Reasons,
  Reviews,
  Inspiration,
  FAQs,
  About,
} from './components_redesign';

interface HomeViewRedesignProps {
  showPrice?: boolean;
}

// Miles design
export const HomeViewRedesign: React.FC<HomeViewRedesignProps> = memo(
  ({ showPrice = false }) => {
    const [toggle, setToggle] = useState(true);
    const [gifToggle, setGifToggle] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (document.querySelector && document.querySelector('.bottomPixel')) {
          document.querySelector('.bottomPixel').getBoundingClientRect().y > 0
            ? setToggle(true)
            : setToggle(false);

          document.querySelector('.bottomPixel').getBoundingClientRect().y > 500
            ? setGifToggle(false)
            : setGifToggle(true);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
      <>
        {/* Hero peak background layer */}
        <div
          style={{
            position: 'absolute',
            top: '-250px',
            height: '500px',
            width: '100%',
            backgroundColor: '#003D70',
            zIndex: 2,
          }}
        />

        {/* Start page sections */}
        <div
          style={{
            position: 'relative',
            zIndex: 2,
            marginBottom: '-10vh',
          }}
        >
          <Hero showPrice={showPrice} />
          <div
            style={{
              position: 'relative',
              bottom: 90,
              height: '0px',
              width: '0px',
            }}
            className="bottomPixel"
          />
        </div>

        <div
          className="reveal-main bg-sky-blue"
          style={toggle ? { height: `100vh` } : { display: 'none' }}
        ></div>

        <div
          className="main bg-sky-blue"
          style={
            toggle
              ? {
                  position: 'fixed',
                  bottom: 0,
                  maxWidth: '100%',
                }
              : {
                  position: 'relative',
                  bottom: 0,
                  maxWidth: '100%',
                }
          }
        >
          <Creation toggle={gifToggle} />
        </div>

        <Occasions />
        {/* To be split tested */}
        <Testimonial />
        <Reasons />
        <Reviews />
        <Inspiration />
        <FAQs />
        <div className="bg-sky-blue">
          <TestimonialRedesign />
          <PoweredBy />
        </div>
        <About />
      </>
    );
  }
);
