import * as React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { LinkButton } from '@app/components/ui/Button';
import { useFunnel } from '@app/contexts/funnel';
import {
  Wrapper,
  Information,
  Price,
  RetailPrice,
  ImageContainer,
  InnerContainer,
} from './Hero.styles';

interface HeroProps {
  showPrice?: boolean;
}

export const Hero: React.FC<HeroProps> = ({ showPrice = false }) => {
  const router = useRouter();
  const { funnel } = useFunnel();

  const headerImage =
    funnel === 'atf-img-no-couch-c3-v2'
      ? '/assets/images/landing-poster-circle.png'
      : '/assets/images/poster-black-frame.gif';

  return (
    <Wrapper split={funnel}>
      {funnel === 'atf-img-orange-couch-bf' && (
        <>
          {/* Small Desktop */}
          <Hidden xs sm md xxl>
            <Image
              priority={true}
              layout="fill"
              objectFit="cover"
              alt="Living room with Moon Map by OverOurMoon;"
              src="/assets/images/hero-livingroom-orange.jpg"
            />
          </Hidden>

          {/* Large Desktop */}
          <Hidden xs sm md lg xl>
            <Image
              priority={true}
              layout="fill"
              objectFit="cover"
              objectPosition="50% 100%"
              alt="Living room with Moon Map by OverOurMoon;"
              src="/assets/images/hero-livingroom-orange.jpg"
            />
          </Hidden>

          {/* Mobile */}
          <Hidden md lg xl xxl>
            <Image
              priority={true}
              layout="fill"
              objectFit="contain"
              objectPosition="50% 100%"
              alt="Living room with Moon Map by OverOurMoon;"
              src="/assets/images/hero-livingroom-orange-mobile.jpg"
            />
          </Hidden>

          {/* Tablet */}
          <Hidden xs sm lg xl xxl>
            <Image
              priority={true}
              layout="fill"
              objectFit="contain"
              objectPosition="50% 100%"
              alt="Living room with Moon Map by OverOurMoon;"
              src="/assets/images/hero-livingroom-orange-mobile.jpg"
            />
          </Hidden>

          <InnerContainer split={funnel}>
            <Container fluid>
              <Row align="center" justify="center">
                <Col md={10} lg={6} xl={5}>
                  <Information>
                    <h2>
                      Your <span>Custom</span> Moon Map
                    </h2>
                    <h4>
                      Create a custom moon phase art print that shows the moon
                      phase exactly as it was on a specific date and location
                      with a personalized message!
                    </h4>

                    {showPrice && (
                      <Price>
                        {' '}
                        Starting at $35 <RetailPrice>$40</RetailPrice>
                      </Price>
                    )}

                    <Hidden xs sm md>
                      <Link href="/create" passHref>
                        <LinkButton variant="primary">
                          Create Your Moon Map
                        </LinkButton>
                      </Link>
                    </Hidden>
                  </Information>
                </Col>

                <Col xs={10} lg={5} xl={5}>
                  <ImageContainer onClick={() => router.push('/create')}>
                    <Image
                      src={headerImage}
                      alt="Living room with Moon Map by OverOurMoon;"
                      width={300}
                      height={395}
                      quality={90}
                      priority={true}
                    />
                  </ImageContainer>
                  <Row>
                    <Col style={{ padding: 0 }}>
                      <Hidden lg xl xxl>
                        <Link href="/create" passHref>
                          <LinkButton
                            variant="primary"
                            style={{ marginBottom: '2.5rem' }}
                          >
                            Create Your Moon Map
                          </LinkButton>
                        </Link>
                      </Hidden>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </InnerContainer>
        </>
      )}

      {/* Control |  */}
      {funnel === 'atf-img-no-couch-c3-v2' && (
        <InnerContainer>
          <Container fluid>
            <Row align="center" justify="center">
              <Col md={10} lg={5}>
                <Information>
                  <h2>
                    Your <span>Custom</span> Moon Map
                  </h2>
                  <h4>
                    Create an 18x24 inch custom moon phase art print that shows
                    the moon phase exactly as it was on a specific date and
                    location with a personalized message!
                  </h4>

                  {showPrice && (
                    <Price>
                      {' '}
                      Starting at $55 <RetailPrice>$110</RetailPrice>
                    </Price>
                  )}

                  <Hidden xs sm md>
                    <Link href="/create" passHref>
                      <LinkButton variant="primary">
                        Create Your Moon Map
                      </LinkButton>
                    </Link>
                  </Hidden>
                </Information>
              </Col>

              <Col lg={7} xl={6}>
                <ImageContainer onClick={() => router.push('/create')}>
                  <Image
                    src={headerImage}
                    alt="Living room with Moon Map by OverOurMoon;"
                    width={1200}
                    height={1200}
                    quality={90}
                    priority={true}
                  />
                </ImageContainer>
                <Row>
                  <Col>
                    <Hidden lg xl xxl>
                      <Link href="/create" passHref>
                        <LinkButton
                          variant="primary"
                          style={{ marginBottom: '2.5rem' }}
                        >
                          Create Your Moon Map
                        </LinkButton>
                      </Link>
                    </Hidden>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </InnerContainer>
      )}
    </Wrapper>
  );
};
