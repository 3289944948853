import React from 'react';
import Link from 'next/link';
import Lazyload from 'react-lazyload';
import { Container, Row, Col } from 'react-grid-system';
import { LinkButton } from '@app/components/ui/Button';
import { ImageContainer, Wrapper } from './styles';

const CALENDAR_ICON = require('./icons/calendar.svg');
const CHECKMARK_ICON = require('./icons/checkmark.svg');
const MESSAGE_ICON = require('./icons/message.svg');

export const Creation: React.FC = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col sm={12}>
          <h2>
            Design Your Perfect Moon Map.
            <br />
            It&apos;s <span>Easy!</span>
          </h2>
          <p>
            So simple and quick you can do it on your phone, tablet or computer!
          </p>
        </Col>
      </Row>

      <Row>
        <Col sm={12} lg={5} offset={{ lg: 1 }}>
          <section>
            <ImageContainer>
              <Lazyload height={48} offset={200} once>
                <img src={CALENDAR_ICON} alt="Location Pin" />
              </Lazyload>
            </ImageContainer>

            <h2>1. Pick A Date & Location</h2>
            <h4>
              Enter any date, in the past or future, and a location anywhere in
              the world!
            </h4>
          </section>
        </Col>

        <Col sm={12} lg={5}>
          <section>
            <ImageContainer>
              <Lazyload height={48} offset={200} once>
                <img src={MESSAGE_ICON} alt="Message Bubble" />
              </Lazyload>
            </ImageContainer>

            <h2>2. Customize</h2>
            <h4>
              Add a custom message and choose from our selection of frame
              options!
            </h4>
          </section>
        </Col>
      </Row>

      <Row>
        <Col sm={6} offset={{ sm: 3 }}>
          <section>
            <ImageContainer>
              <Lazyload height={48} offset={200} once>
                <img src={CHECKMARK_ICON} alt="Checkmark" />
              </Lazyload>
            </ImageContainer>

            <h2>3. Review & Finish</h2>
            <h4>
              When you&apos;re finished customizing your Moon Map, we&apos;ll
              ship it to you for free!
            </h4>
          </section>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <section>
            <Link href="/create" passHref>
              <LinkButton variant="primary">Start Designing Now</LinkButton>
            </Link>
          </section>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Creation;
