export const Checkmark = (): JSX.Element => (
  <svg
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="10"
      height="1.99997"
      rx="0.999984"
      transform="matrix(-0.707095 -0.707118 0.707095 -0.707118 8.01501 15.8994)"
      fill="#40ADB7"
    />
    <rect
      width="20"
      height="1.99997"
      rx="0.999984"
      transform="matrix(0.707095 -0.707118 -0.707095 -0.707118 8.01501 15.8994)"
      fill="#40ADB7"
    />
  </svg>
);
