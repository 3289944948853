import React, { useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import {
  Wrapper,
  Heading,
  QuestionsWrapper,
  AccordionWrapper,
  AccordionTitle,
  ArrowWrapper,
} from './FAQs.styles';

const Accordion = ({ children, title }) => {
  const [isOpen, setOpen] = useState(false);
  const { display, opacity, rotateZ } = useSpring({
    from: { display: 'none', opacity: 0, rotateZ: 0 },
    to: {
      display: isOpen ? 'block' : 'none',
      opacity: isOpen ? 1 : 0,
      rotateZ: isOpen ? 90 : 0,
    },
  });

  const Arrow = () => (
    <ArrowWrapper>
      <animated.svg
        width="10"
        height="18"
        viewBox="0 0 10 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ rotateZ }}
        onClick={() => setOpen(!isOpen)}
      >
        <rect
          width="11.9998"
          height="1.99997"
          rx="0.999984"
          transform="matrix(-0.707118 0.707095 -0.707118 -0.707095 9.89954 8.98535)"
          fill="#FFBF00"
        />
        <rect
          width="11.9998"
          height="1.99997"
          rx="0.999984"
          transform="matrix(-0.707118 -0.707095 -0.707118 0.707095 9.89954 8.98535)"
          fill="#FFBF00"
        />
      </animated.svg>
    </ArrowWrapper>
  );

  return (
    <AccordionWrapper>
      <Arrow />
      <AccordionTitle onClick={() => setOpen(!isOpen)}>{title}</AccordionTitle>
      <animated.div
        style={{
          display,
          opacity,
        }}
        className="accordionBody"
      >
        {children}
      </animated.div>
    </AccordionWrapper>
  );
};

export const FAQs: React.FC = () => {
  return (
    <Wrapper>
      <Heading>Frequently Asked Questions</Heading>
      <QuestionsWrapper>
        <Accordion title={'What is OverOurMoon?'}>
          <p>
            The OverOurMoon Moon Map is a printed map of the exact phase the
            moon was in during the night based on a specific date and location.
            An OverOurMoon Moon Map turns a magical memory into a custom piece
            of art that is a great addition to any room.
          </p>
        </Accordion>

        <Accordion title={'Where are you located?'}>
          <p>We are located in sunny San Diego, California.</p>
        </Accordion>

        <Accordion title={'Refund & Return Policy'}>
          <p>If you are unhappy with your order because:</p>
          <p>-The order was damaged in transit</p>
          <p>
            -You received the wrong order and the order you received did not
            match the design you submitted to us and shown on your order
            confirmation page.
          </p>
          <p>
            We can issue a replacement or a refund, after you have supplied
            photos of the moon map you received / photos of the damaged item and
            packaging.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            If there was an error on the order because you entered the wrong
            shipping address, the details were entered wrong while creating your
            moon map or you included the wrong items in your order, we
            don&apos;t accept returns for customer error, or customer oversight.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            We don&apos;t offer returns for a refund for an item created for an
            event that is subsequently cancelled. In these cases please contact{' '}
            <span style={{ fontStyle: 'italic' }}>help@overourmoon.com</span>{' '}
            and customer service will help.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            We don&apos;t offer refunds if you entered an incomplete or
            incorrect shipping address or if the item is returned to us by the
            shipping service due to the item being undeliverable. Instead we
            offer to re ship the order once returned, for a re-shipping and
            repackaging fee depending on the country the order is being
            re-shipped to. Contact customer service at{' '}
            <span style={{ fontStyle: 'italic' }}>help@overourmoon.com</span>{' '}
            for more information.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            We don&apos;t offer refunds for digital download orders where the
            item is the digital version of the Moon Map.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            All refunds will be based on the cost of the product minus the cost
            of shipping paid originally on the order.
          </p>
          <p>
            Please contact{' '}
            <span style={{ fontWeight: 'bold' }}>help@overourmoon.com</span> for
            more information.
          </p>
        </Accordion>

        <Accordion title={'How can I edit after I have placed my order?'}>
          <p>
            After you&apos;ve placed your order we will send you a confirmation
            email with links that will let you check on and/edit your print. Any
            editing needs to be done within 24 hours of placing your order,
            unless you have pre-approved your order (pre-approved orders bypass
            the 24 hour wait period). After that time, the order will be in the
            in the print and quality control process, so it will be too late to
            edit. If you have an urgent request you can email us at
            help@overourmoon.com and we will do our best to help you.
          </p>
        </Accordion>
      </QuestionsWrapper>
    </Wrapper>
  );
};
