import styled from 'styled-components';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  padding: 2rem 1rem;
  text-align: center;

  h2 {
    ${(props: any) => props.theme.fonts.secondary}
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.1;
    margin-top: 0;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    font-weight: 350;
  }

  ${media.lg` 
    border-top: none;

    h2 {
      text-align: left;
    }

    p {
      text-align: left;
    }
  `}
`;

export const Block = styled.div<{ border?: boolean }>`
  padding: 1.5rem 0.5rem;

  ${media.lg` 
    padding: 0 2.5rem;
    ${props => props.border && 'border-left: 1px solid #eee;'};
  `}
`;
