import React, { createContext, useState, useContext } from 'react';

interface FunnelState {
  funnel: string;
}

export const FunnelContext = createContext<FunnelState>(null);

export const FunnelProvider: React.FC<FunnelState> = ({ children, funnel }) => {
  const [state] = useState<FunnelState>({ funnel });

  return (
    <FunnelContext.Provider value={{ ...state }}>
      {children}
    </FunnelContext.Provider>
  );
};

export function useFunnel(): FunnelState {
  return useContext(FunnelContext);
}
