import styled from 'styled-components';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  text-align: center;
  padding-top: 2.5rem;

  section {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .borders {
    > div:nth-child(odd) {
      border-right: 1px solid #eee;
    }

    ${media.lg`
    > div:not(:last-child) {
      border-right: 1px solid #eee;
    }
  `}

    > div:nth-child(-n + 2) {
      border-bottom: 1px solid #eee;
      padding-bottom: 2rem;

      ${media.lg`
        border-bottom: none;
      `}
    }
  }

  .colWidth {
    ${media.xxl`
      > div {
        max-width: 75%;
        margin: auto !important;
      }
    `}

    ${media.lg`
      > div {
        max-width: 85%;
        margin: auto !important;
      }
    `}
  }
`;

const Checkmark = require('./icons/checkmark.svg');
export const Column = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  > div {
    padding-left: 0.5em;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    ${media.xs`
    background: transparent url(${Checkmark}) center left no-repeat;
    background-size: 22px 22px;
    display: block;
    padding-left: 2.5rem;
  `}
  }
`;

export const SvgWrapper = styled.div`
  > svg {
    width: 2.5rem;
    margin: 1.5rem;
  }
`;
