import React from 'react';
import Link from 'next/link';
import { LinkButton } from '@app/components/ui/Button';
import {
  Wrapper,
  ButtonWrapper,
  QuoteWrapper,
  LeftQuote,
  RightQuote,
  Quote,
  Name,
} from './Testimonial.styles';

export const Testimonial: React.FC = () => {
  return (
    <Wrapper>
      <QuoteWrapper>
        <LeftQuote>&ldquo;</LeftQuote>
        <Quote>
          <span>
            I got this gift for my brother, it was absolutely amazing and looks
            even better than the photos!
          </span>
          <br />
          To see him speechless and so amazed was my main goal in ordering it
          for him. I ordered it to commemorate the birth of his first child and
          used a message that our father had written to us before he passed
          away. We both got really emotional, so it&rsquo;s definitely something
          he will be able to cherish forever.
        </Quote>
        <Name>- Rachel B.</Name>
        <RightQuote>&rdquo;</RightQuote>
      </QuoteWrapper>

      <ButtonWrapper>
        <Link href="/create" passHref>
          <LinkButton variant="primary">Create Your Moon Map</LinkButton>
        </Link>
      </ButtonWrapper>
    </Wrapper>
  );
};
