import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useTrail, animated } from '@react-spring/web';
import { isOnScreen } from '@app/lib/utils';
import { LinkButton } from '@app/components/ui/Button';
import {
  Wrapper,
  Header,
  ListWrapper,
  List,
  ListItem,
  CheckmarkWrapper,
  Reason1,
  Reason2,
  Reason3,
  Reason4,
  ButtonWrapper,
} from './Occasions.styles';
import { Award, Checkmark, PaintBrush, Shipping, Trophy } from './icons';

const items = [
  'Anniversaries',
  'Birthdays',
  'Weddings',
  'Engagements',
  "Mother's Day",
  "Father's Day",
  'Remembrance',
  'Graduations',
  'Retirement',
  'Big Milestones',
  "Valentine's Day",
  'Holidays',
];

export const Occasions: React.FC = () => {
  const [toggle, set] = useState(true);
  const trail = useTrail(items.length, {
    opacity: toggle ? 0 : 1,
    y: toggle ? -100 : 0,
    from: { opacity: 0, y: -100 },
  });

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.querySelector &&
        document.querySelector('.occasions-animation-trigger')
      ) {
        const elem = document.querySelector('.occasions-animation-trigger');
        isOnScreen(elem) && set(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <Wrapper>
      <Header>
        <h2 className="occasions-animation-trigger">
          A Unique &amp; Personalized Gift for Every Occasion!
        </h2>
      </Header>

      <ListWrapper>
        <List>
          {trail.map(({ y, ...rest }, index) => (
            <animated.div
              key={items[index]}
              style={{
                ...rest,
                transform: y.to(y => `translate3d(${y}px,0,0)`),
              }}
              className="col-start-1 col-span-12 md:col-auto"
            >
              <animated.div className="grid grid-cols-12">
                <CheckmarkWrapper>
                  <Checkmark />
                </CheckmarkWrapper>
                <ListItem>{items[index]}</ListItem>
              </animated.div>
            </animated.div>
          ))}
        </List>
      </ListWrapper>

      <Reason1>
        <Award />
        <p>Astronomically Verified</p>
      </Reason1>
      <Reason2>
        <PaintBrush />
        <p>Fully Customizable</p>
      </Reason2>
      <Reason3>
        <Trophy />
        <p>Premium Quality Print</p>
      </Reason3>
      <Reason4>
        <Shipping />
        <p>Free Shipping &amp; Handling</p>
      </Reason4>

      <ButtonWrapper>
        <Link href="/create" passHref>
          <LinkButton variant="primary" className="my-10">
            Create Your Moon Map
          </LinkButton>
        </Link>
      </ButtonWrapper>
    </Wrapper>
  );
};
