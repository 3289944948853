import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  ${tw`grid grid-cols-12 lg:gap-y-16 lg:pb-20 bg-sky-blue text-night-sky`}
`;

export const Heading = styled.h2`
  ${tw`col-start-2 col-span-10 text-center mt-20`}
  ${props => props.theme.fonts.headline_1}
    font-size: 3rem;

  span {
    ${tw`italic`}
  }

  ${media.lg`
      ${props => props.theme.fonts.headline_1}
    `}
`;

export const ImageWrapper = styled.div`
  ${tw`col-start-2 col-span-10 lg:col-start-3 lg:col-span-4 xxxl:col-start-4 xxxl:col-span-3 text-center`}

  > div {
    ${tw`rounded-xl lg:rounded-3xl`}
  }
`;

export const TextWrapper = styled.div`
  ${tw`col-start-2 col-span-10 lg:col-start-8 lg:col-span-3 xxxl:col-start-7 xxxl:col-span-2 xxxl:ml-20 self-center text-center lg:text-left mb-20 lg:mb-0`}

  h3 {
    ${props => props.theme.fonts.headline_3}
    font-size: 2rem;
    ${tw`mb-0 lg:mt-0`}

    ${media.lg`
      ${props => props.theme.fonts.headline_3}
    `}
  }

  p {
    ${props => props.theme.fonts.body}
  }
`;

export const ImageWrapperAlt = styled.div`
  ${tw`col-start-2 col-span-10 lg:col-start-7 lg:col-span-4 xxxl:col-start-7 xxxl:col-span-3 text-center`}

  > div {
    ${tw`rounded-xl lg:rounded-3xl`}
  }
`;

export const TextWrapperAlt = styled.div`
  ${tw`col-start-2 col-span-10 lg:col-start-3 lg:col-span-3 xxxl:col-start-4 xxxl:col-span-2 self-center text-center lg:text-left mb-20 lg:mb-0 xl:pl-16 xxl:pl-20 xxxl:pl-20`}

  h3 {
    ${props => props.theme.fonts.headline_3}
    font-size: 2rem;
    ${tw`mb-0 lg:mt-0`}

    ${media.lg`
      ${props => props.theme.fonts.headline_3}
    `}
  }

  p {
    ${props => props.theme.fonts.body}
  }
`;
