import { useState, useEffect, Component } from 'react';
import React, { CSSProperties } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { LinkButton } from '@app/components/ui/Button';
import { getOrientation } from '@app/lib/utils/getOrientation';
import {
  Wrapper,
  SlideWrapper,
  SlideTitle,
  SlideGif,
  SlideCopy,
  ButtonWrapper,
  TopSpacer,
  BottomSpacer,
} from './Creation.styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

interface SlideProps {
  srcIndex: number;
  toggle?: boolean;
}

const Step1: React.FC<SlideProps> = ({ srcIndex, toggle }) => {
  const [imgSrc, setImgSrc] = useState<string>('/assets/images/step_1.jpg');

  useEffect(() => {
    srcIndex === 0 && toggle
      ? setImgSrc('/assets/images/step_1.gif')
      : setImgSrc('/assets/images/step_1.jpg');
  }, [srcIndex, toggle]);

  const [orientation, setOrientation] = useState<string>();
  useEffect(() => {
    setOrientation(getOrientation());
  }, []);

  window.addEventListener(
    'resize',
    function () {
      setOrientation(getOrientation());
    },
    false
  );

  return (
    <SlideWrapper className="bg-sky-blue">
      <TopSpacer />
      <SlideTitle orientation={orientation}>
        <h2>
          Design Your Perfect Moon Map. It&apos;s <span>Easy!</span>
        </h2>
        <p>
          So simple and quick you can do it on your phone, tablet, or computer!
        </p>
      </SlideTitle>
      <SlideGif orientation={orientation}>
        <Image
          quality={100}
          priority={true}
          height={300}
          width={300}
          alt="Customize Your Design"
          src={imgSrc}
        />
      </SlideGif>

      <SlideCopy orientation={orientation}>
        <h3>
          Customize Your
          <br /> Design
        </h3>
        <p>
          Add a custom message and choose from our selection of frame options!
        </p>
      </SlideCopy>
      <ButtonWrapper orientation={orientation}>
        <Link href="/create" passHref>
          <LinkButton
            variant="primary"
            className="mb-2 xs:mb-0 xs:mt-4 lg:mt-14"
          >
            Start Designing Now
          </LinkButton>
        </Link>
      </ButtonWrapper>
      <BottomSpacer />
    </SlideWrapper>
  );
};

const Step2: React.FC<SlideProps> = ({ srcIndex, toggle }) => {
  const [imgSrc, setImgSrc] = useState<string>('/assets/images/step_2.jpg');

  useEffect(() => {
    srcIndex === 1 && toggle
      ? setImgSrc('/assets/images/step_2.gif')
      : setImgSrc('/assets/images/step_2.jpg');
  }, [srcIndex, toggle]);

  const [orientation, setOrientation] = useState<string>();
  useEffect(() => {
    setOrientation(getOrientation());
  }, []);

  window.addEventListener(
    'resize',
    function () {
      setOrientation(getOrientation());
    },
    false
  );

  return (
    <SlideWrapper className="bg-deep-blue">
      <TopSpacer />
      <SlideTitle orientation={orientation}>
        <h2 className="text-white">
          Design Your Perfect Moon Map. It&apos;s <span>Easy!</span>
        </h2>
        <p className="text-white">
          So simple and quick you can do it on your phone, tablet, or computer!
        </p>
      </SlideTitle>
      <SlideGif orientation={orientation}>
        <Image
          quality={100}
          priority={true}
          height={300}
          width={300}
          alt="Pick A Date and Location"
          src={imgSrc}
        />
      </SlideGif>

      <SlideCopy orientation={orientation}>
        <h3 className="text-white">
          Pick A Date &amp;
          <br /> Location
        </h3>
        <p className="text-white">
          Enter any date, in the past or future, and a location anywhere in the
          world!
        </p>
      </SlideCopy>
      <ButtonWrapper orientation={orientation}>
        <Link href="/create" passHref>
          <LinkButton
            variant="primary"
            className="mb-2 xs:mb-0 xs:mt-4 lg:mt-14"
          >
            Start Designing Now
          </LinkButton>
        </Link>
      </ButtonWrapper>
      <BottomSpacer />
    </SlideWrapper>
  );
};

const Step3: React.FC<SlideProps> = ({ srcIndex, toggle }) => {
  const [imgSrc, setImgSrc] = useState<string>('/assets/images/step_3.jpg');

  useEffect(() => {
    srcIndex === 2 && toggle
      ? setImgSrc('/assets/images/step_3.gif')
      : setImgSrc('/assets/images/step_3.jpg');
  }, [srcIndex, toggle]);

  const [orientation, setOrientation] = useState<string>();
  useEffect(() => {
    setOrientation(getOrientation());
  }, []);

  window.addEventListener(
    'resize',
    function () {
      setOrientation(getOrientation());
    },
    false
  );

  return (
    <SlideWrapper className="bg-night-sky">
      <TopSpacer />
      <SlideTitle orientation={orientation}>
        <h2 className="text-white">
          Design Your Perfect Moon Map. It&apos;s <span>Easy!</span>
        </h2>
        <p className="text-white">
          So simple and quick you can do it on your phone, tablet, or computer!
        </p>
      </SlideTitle>
      <SlideGif orientation={orientation}>
        <Image
          quality={100}
          priority={true}
          height={300}
          width={300}
          alt="Review and Finish"
          src={imgSrc}
        />
      </SlideGif>

      <SlideCopy orientation={orientation}>
        <h3 className="text-white">
          Review
          <br /> &amp; Finish
        </h3>
        <p className="text-white">
          When you&apos;re finished customizing your Moon Map, we&apos;ll ship
          it to you for free!
        </p>
      </SlideCopy>
      <ButtonWrapper orientation={orientation}>
        <Link href="/create" passHref>
          <LinkButton
            variant="primary"
            className="mb-2 xs:mb-0 xs:mt-4 lg:mt-14"
          >
            Start Designing Now
          </LinkButton>
        </Link>
      </ButtonWrapper>
      <BottomSpacer />
    </SlideWrapper>
  );
};

export class Creation extends Component<
  { toggle: boolean },
  { currentSlide: number; autoPlay: boolean }
> {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      autoPlay: false,
    };
  }

  next = () => {
    this.setState(state => ({
      currentSlide: state.currentSlide + 1,
    }));
  };

  prev = () => {
    this.setState(state => ({
      currentSlide: state.currentSlide - 1,
    }));
  };

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.toggle !== this.props.toggle) {
      this.setState(() => ({
        autoPlay: this.props.toggle,
      }));
    }
  }

  render() {
    const arrowStyles: CSSProperties = {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      cursor: 'pointer',
      border: 'none',
      background: 'none',
    };

    const carouselProps = {
      infiniteLoop: true,
      showStatus: false,
      transitionTime: 225,
      autoPlay: this.state.autoPlay,
      interval: 6400,
      stopOnHover: false,
      swipeScrollTolerance: 25,
      showIndicators: true,
      showThumbs: false,
      preventMovementUntilSwipeScrollTolerance: true,
      onChange: this.updateCurrentSlide,
      selectedItem: this.state.currentSlide,
      renderArrowPrev: (onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={this.prev}
            title={label}
            style={{ ...arrowStyles, left: 30 }}
          >
            <svg
              width="37"
              height="8"
              viewBox="0 0 37 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden lg:block"
            >
              <path
                d="M0.646443 3.64645C0.451181 3.84171 0.451181 4.15829 0.646443 4.35355L3.82842 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.73079 7.34027 4.73079 7.02369 4.53553 6.82843L1.7071 4L4.53553 1.17157C4.73079 0.976311 4.73079 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82842 0.464466L0.646443 3.64645ZM37 3.5L0.999996 3.5V4.5L37 4.5V3.5Z"
                fill={this.state.currentSlide === 0 ? '#003D70' : '#FFF9F4'}
              />
            </svg>
          </button>
        ),
      renderArrowNext: (onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={this.next}
            title={label}
            style={{ ...arrowStyles, right: 30 }}
          >
            <svg
              width="36"
              height="8"
              viewBox="0 0 36 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden lg:block"
            >
              <path
                d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64645L32.1716 0.464466C31.9763 0.269204 31.6597 0.269204 31.4645 0.464466C31.2692 0.659728 31.2692 0.976311 31.4645 1.17157L34.2929 4L31.4645 6.82843C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.7308 31.9763 7.7308 32.1716 7.53553L35.3536 4.35355ZM0 4.5H35V3.5H0V4.5Z"
                fill={this.state.currentSlide === 0 ? '#003D70' : '#FFF9F4'}
              />
            </svg>
          </button>
        ),
    };

    return (
      <Wrapper index={this.state.currentSlide}>
        <Carousel {...carouselProps}>
          <Step1
            srcIndex={this.state.currentSlide}
            toggle={this.props.toggle}
          />
          <Step2
            srcIndex={this.state.currentSlide}
            toggle={this.props.toggle}
          />
          <Step3
            srcIndex={this.state.currentSlide}
            toggle={this.props.toggle}
          />
        </Carousel>
      </Wrapper>
    );
  }
}
