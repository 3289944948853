import Link from 'next/link';
import Image from 'next/image';
import LazyLoad from 'react-lazyload';
import { LinkButton } from '@app/components/ui/Button';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { PHOTO_SET } from './photos';
import {
  Wrapper,
  GalleryWrapper,
  MobileGalleryWrapper,
  PhotoWrapper,
  Heading,
  LinkWrapper,
} from './Inspiration.styles';

export const Inspiration: React.FC = () => {
  const carouselProps = {
    showStatus: false,
    transitionTime: 225,
    autoPlay: false,
    swipeScrollTolerance: 50,
    showThumbs: false,
    showArrows: false,
    centerMode: true,
    centerSlidePercentage: 95,
  };

  return (
    <Wrapper>
      <Heading>
        <h2>
          One of a Kind
          <br /> Custom <span>Piece of Art!</span>
        </h2>
        <h4>It will be the perfect sentimental addition to any home.</h4>
      </Heading>

      <MobileGalleryWrapper>
        <Carousel {...carouselProps}>
          {PHOTO_SET.map((image, i) => (
            <PhotoWrapper key={i}>
              <LazyLoad height={300} offset={400} once>
                <Image
                  src={image.src}
                  alt={'Moon Map by OverOurMoon;'}
                  width={300}
                  height={300}
                  quality={50}
                />
              </LazyLoad>
            </PhotoWrapper>
          ))}
        </Carousel>
      </MobileGalleryWrapper>

      <GalleryWrapper>
        {PHOTO_SET.map((image, i) => (
          <PhotoWrapper key={i}>
            <LazyLoad height={300} offset={200} once>
              <Image
                src={image.src}
                alt={'Moon Map by OverOurMoon;'}
                width={300}
                height={300}
                quality={50}
              />
            </LazyLoad>
          </PhotoWrapper>
        ))}
      </GalleryWrapper>

      <LinkWrapper>
        <Link href="/create" passHref>
          <LinkButton variant="primary">Create Your Moon Map</LinkButton>
        </Link>
      </LinkWrapper>
    </Wrapper>
  );
};

export default Inspiration;
