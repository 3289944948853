import styled from 'styled-components';
import { media } from '@app/styles';

interface SplitProps {
  split?: string;
}

export const Wrapper = styled.div<SplitProps>`
  position: relative;
  text-align: center;

  background-color: ${props =>
    props.split === 'atf-img-no-couch-c3-v2'
      ? `${props.theme.bgColors['light-blue-200']}`
      : 'white'};

  ${props =>
    props.split === 'atf-img-orange-couch-bf'
      ? `min-height: calc(100vh - 100px);`
      : null};

  ${media.lg`
        text-align: left;
        ${props =>
          props.split === 'atf-img-orange-couch-bf'
            ? 'min-height: 0; height: calc(100vh - 100px); max-height: 1000px;'
            : null};
  `};

  ${media.xxl`
    ${props =>
      props.split === 'atf-img-orange-couch-bf' ? 'min-height: 60vh;' : null};
  `};
`;

export const InnerContainer = styled.div<SplitProps>`
  max-width: 1280px;
  margin: 0 auto;
  padding: ${props =>
    props.split === 'atf-img-orange-couch-bf'
      ? '1.2rem 0 4rem 0'
      : '3rem 0 4rem 0'};

  ${media.lg`
    padding: 1.2rem 0;

    ${props =>
      props.split === 'atf-img-orange-couch-bf'
        ? 'padding: 3rem 0 1.2rem'
        : null};
  `};

  ${media.xxl`
    ${props =>
      props.split === 'atf-img-orange-couch-bf'
        ? 'padding: 6rem 0 1.2rem'
        : null};
  `};
`;

export const ImageContainer = styled.div`
  max-width: 500px;
  margin: 0 auto 2rem;
  padding: 0 2rem;
  cursor: pointer;
  border: none;
  z-index: 1;
  text-align: center;

  ${media.md`
    padding: 1rem;
  `};

  ${media.lg`
    margin: 0 auto;
  `};
`;

export const Information = styled.div`
  h2 {
    ${(props: any) => props.theme.fonts.secondary}
    font-size: 2.2rem;
    font-weight: 400;
    text-align: center;
    line-height: 110%;
    margin: 0;
  }

  h4 {
    text-align: center;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 350;
    margin 1rem 0;
  }

  span {
    font-style: italic;
  }

  ${media.lg`
    h2 {
      text-align: left;
      font-size: 3.5rem;
    }

    h4 {
      text-align: left;
      font-size: 1.1rem;
      line-height: 1.8;
      margin: 2rem 0;
    }
  `}
`;

export const Price = styled.div`
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 0.25rem;
  text-align: center;

  ${media.lg`
    text-align: left;
    margin-bottom: 2rem;
  `}
`;

export const RetailPrice = styled.span`
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 400;
  margin-left: 4px;
  color: #e44444;
  text-decoration: line-through;
`;
