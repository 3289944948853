import React from 'react';
import Link from 'next/link';
import { Container, Row, Col } from 'react-grid-system';
import { LinkButton } from '@app/components/ui/Button';
import { Award, PaintBrush, Shipping, Trophy } from './icons';
import { Wrapper, SvgWrapper, Column } from './styles';

export const Occasions: React.FC = () => (
  <Wrapper>
    <Container style={{ padding: '10px' }}>
      <Col>
        <h2>A Unique &amp; Personalized Gift For Every Occasion!</h2>
      </Col>

      <section>
        <Column>
          <div>Anniversaries</div>
          <div>Birthdays</div>
          <div>Weddings</div>
          <div>Engagements</div>
          <div>Mother&apos;s Day</div>
          <div>Father&apos;s Day</div>
        </Column>
        <Column>
          <div>Remembrance</div>
          <div>Graduations</div>
          <div>Retirement</div>
          <div>Big Milestones</div>
          <div>Valentine&apos;s Day</div>
          <div>Holidays</div>
        </Column>
      </section>

      <Link href="/create" passHref>
        <LinkButton variant="primary" style={{ marginTop: '1.5rem' }}>
          Create Your Moon Map
        </LinkButton>
      </Link>
      <Row nogutter style={{ paddingTop: '2.5rem' }} className="borders">
        <Col xs={6} lg={2} offset={{ lg: 2 }}>
          <SvgWrapper>
            <PaintBrush />
          </SvgWrapper>
          <div>
            Fully <br /> Customizable
          </div>
        </Col>
        <Col xs={6} lg={2}>
          <SvgWrapper>
            <Trophy />
          </SvgWrapper>
          <div>
            Premium <br />
            Quality Print
          </div>
        </Col>
        <Col xs={6} lg={2}>
          <SvgWrapper>
            <Award />
          </SvgWrapper>
          <div>
            Money Back <br />
            Guarantee
          </div>
        </Col>
        <Col xs={6} lg={2}>
          <SvgWrapper>
            <Shipping />
          </SvgWrapper>
          <div>
            Free Shipping &amp; <br /> Handling
          </div>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Occasions;
