import React from 'react';
import Image from 'next/image';
import { Wrapper, ImageWrapper, Heading, BodyWrapper } from './About.styles';

export const About: React.FC = () => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          quality={50}
          height={821}
          width={1500}
          alt="San Diego Skyline With Moon Overhead"
          src="/assets/images/san-diego-skyline.png"
        />
      </ImageWrapper>
      <Heading>
        Made in <span>Sunny</span> San Diego
      </Heading>
      <BodyWrapper>
        <p>We are a small company based in sunny San Diego, California.</p>
        <p>
          Committed to making beautiful art, we started OverOurMoon so
          you&apos;d have meaningful gifts for the people you love, regardless
          of whether they&apos;re male or female, young or old.
        </p>
        <p>
          Across the world, the moon captivates us with its beauty. Knowing
          you&apos;re looking up at the same moon as the people you care about
          is an enchanting thought. We wanted to capture this wonder and give
          you a way to share it!
        </p>
        <p>
          An OverOurMoon Moon Map is a piece of personalized art that&apos;s
          unique, tasteful and looks great in any home.
        </p>
      </BodyWrapper>
    </Wrapper>
  );
};
