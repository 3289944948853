export const PHOTO_SET = [
  {
    src: require('./images/inspiration01.jpg'),
    width: 1,
    height: 1,
  },
  {
    src: require('./images/inspiration02.jpg'),
    width: 1,
    height: 1,
  },
  {
    src: require('./images/inspiration03.jpg'),
    width: 1,
    height: 1,
  },
  {
    src: require('./images/inspiration04.jpg'),
    width: 1,
    height: 1,
  },
  {
    src: require('./images/inspiration05.jpg'),
    width: 1,
    height: 1,
  },
  {
    src: require('./images/inspiration06.jpg'),
    width: 1,
    height: 1,
  },
];
