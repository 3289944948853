import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  ${tw`grid grid-cols-12 bg-sky-blue text-night-sky py-20`}
`;

export const Heading = styled.h2`
  ${tw`col-start-2 col-span-10 lg:col-start-3 lg:col-span-4 xxl:col-start-4 xxl:col-span-3 text-center lg:text-left m-0 mb-10 lg:m-0`}
  ${props => props.theme.fonts.headline_1}
  font-size: 3rem;

  ${media.lg`
    ${props => props.theme.fonts.headline_1}
  `}
`;

export const QuestionsWrapper = styled.div`
  ${tw`col-start-2 col-span-11 lg:col-start-7 lg:col-span-5 xxl:col-span-3`}
`;

export const AccordionWrapper = styled.div`
  ${tw`grid grid-cols-12 items-center`}

  .accordionBody {
    ${tw`col-start-2 col-span-10`}
  }

  p {
    ${tw`mt-0`}
  }
`;

export const AccordionTitle = styled.h4`
  ${props => props.theme.fonts.subhead}
  ${tw`col-start-2 col-span-11 cursor-pointer`}
  font-size: 1.1rem;

  ${media.lg`
    ${props => props.theme.fonts.subhead}
  `}
`;

export const AccordionBody = styled.div`
  ${props => props.theme.fonts.body}
`;

export const ArrowWrapper = styled.div`
  ${tw`col-start-1 col-span-1 cursor-pointer`}
`;
