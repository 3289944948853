import React, { memo } from 'react';
import { PoweredBy } from '@app/components/PoweredBy';
import { Testimonial } from '@app/components/Testimonial';
import {
  Hero,
  Creation,
  Inspiration,
  Reasons,
  Reviews,
  WhatIs,
  Occasions,
} from './components';

interface HomeViewProps {
  showPrice?: boolean;
  faqs?: any[];
}

export const HomeView: React.FC<HomeViewProps> = memo(
  ({ showPrice = false }) => {
    return (
      <>
        <Hero showPrice={showPrice} />

        <>
          <Occasions />
          <Reviews />
          <Reasons />
          <Creation />
          <Inspiration />
          <WhatIs />
        </>

        <Testimonial />
        <PoweredBy />
      </>
    );
  }
);
