import * as React from 'react';
import Link from 'next/link';
import { Container, Row, Col } from 'react-grid-system';
import { LinkButton } from '@app/components/ui/Button';
import { Block, Wrapper } from './styles';

export const WhatIs: React.FC = () => (
  <Wrapper>
    <Container>
      <Row nogutter>
        <Col xs={12} lg={6}>
          <Block>
            <h2>What Is A Moon Map?</h2>
            <p>
              The OverOurMoon Moon Map shows you exactly what the moon looked
              like and the unique phase it was in based on a specific date and
              location. An OverOurMoon Moon Map turns a magical memory into a
              custom piece of art that is a great addition to any room.
            </p>
          </Block>
        </Col>
        <Col xs={12} lg={6}>
          <Block border>
            <h2>How Do I Know Your Moon Maps Are Real And Accurate?</h2>
            <p>
              We&apos;ve spent a lot of time working on the technology behind
              our Moon Maps. We use complex mathematical algorithms to calculate
              the position of the sun relative to the earth and the moon, based
              on a specific date and location. These calculations are based on
              actual data collected by scientists, astronomers, and sources like
              NASA, that allows us to determine the exact moon phase visible,
              whether it&apos;s in the past, present, or future.
            </p>
          </Block>
        </Col>
      </Row>

      <Row nogutter>
        <Col style={{ textAlign: 'center' }}>
          <Link href="/create">
            <LinkButton variant="primary" style={{ marginTop: '2.5rem' }}>
              Create Your Moon Map
            </LinkButton>
          </Link>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default WhatIs;
