import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  ${tw`grid grid-cols-12 py-10 lg:py-20 bg-moon-sand text-night-sky`}
`;

export const ImageWrapper = styled.div`
  ${tw`col-start-2 col-span-10 lg:col-start-3 lg:col-span-8 xxl:col-start-4 xxl:col-span-6`}
`;

export const Heading = styled.h2`
  ${tw`col-start-2 col-span-10 lg:col-start-3 lg:col-span-4 xxl:col-start-4 xxl:col-span-3 mt-16 lg:mt-20`}
  ${props => props.theme.fonts.headline_1}
  font-size: 3rem;

  > span {
    ${tw`italic`}
  }

  ${media.lg`
    ${props => props.theme.fonts.headline_1}
  `}
`;

export const BodyWrapper = styled.div`
  ${props => props.theme.fonts.body}
  ${tw`col-start-2 col-span-10 lg:col-start-7 lg:col-span-4 xxl:col-span-3 mt-0 lg:mt-20`}

  p:not(:first-child) {
    ${tw`my-10`}
  }
`;
