import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`grid grid-cols-12`}

  img {
    ${tw`cursor-default`}
  }
`;

export const Heading = styled.div`
  ${tw`col-start-2 col-span-10 text-center`}

  h2 {
    ${props => props.theme.fonts.secondary}
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 0rem;
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
    width: 83.33333%;
    margin: 1rem auto 3rem auto;
  }
`;

export const GalleryWrapper = styled.div`
  ${tw`grid col-start-1 col-span-12 md:col-start-2 md:col-span-10 xl:col-start-3 xl:col-span-8 xxl:col-start-4 xxl:col-span-6 grid-cols-12 gap-y-3 lg:gap-y-4 gap-x-3 lg:gap-x-4 xxl:gap-x-0 justify-items-center`}
`;

export const PhotoWrapper = styled.div`
  ${tw`col-span-12 md:col-span-4`}

  img {
    ${tw`rounded-xl`}}
  }
`;

export const LinkWrapper = styled.div`
  ${tw`col-start-2 col-span-10 text-center z-10`}
`;
