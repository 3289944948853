import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { animated, useSpring } from '@react-spring/web';
import { LinkButton } from '@app/components/ui/Button';
import { getOrientation } from '@app/lib/utils/getOrientation';
import {
  HeroWrapper,
  HeroInner,
  BannerCopy,
  HeroImageWrapper,
  SubHeroWrapper,
  SubHeroImageWrapper,
  SubHeroBannerCopy,
  SubHeroMobileWrapper,
  SubHeroMobileBanneryCopy,
  ButtonWrapper,
  MobileImageWrapper,
  Price,
  RetailPrice,
} from './Hero.styles';

interface HeroProps {
  showPrice: boolean;
}

export const Hero: React.FC<HeroProps> = ({ showPrice = false }) => {
  const [scrollY, setScrollY] = useState(0);
  const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
    springscrollY: 0,
  }));

  springsetScrollY.start({ springscrollY: scrollY });

  const layer_1 = springscrollY.to(o => `translateY(${o * -0.2}px)`);
  const layer_2 = springscrollY.to(o => `translateY(${o * -0.4}px)`);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const [orientation, setOrientation] = useState<string>();
  useEffect(() => {
    setOrientation(getOrientation());
  }, []);

  window.addEventListener(
    'resize',
    function () {
      setOrientation(getOrientation());
    },
    false
  );

  return (
    <>
      {/* Hero peak image layer */}
      {/* <div
        style={{
          position: 'absolute',
          top: '-100px',
          width: '100%',
        }}
      >
        <Image
          quality={50}
          priority={true}
          width={660}
          height={499}
          alt="Night sky with stars"
          src="/assets/images/secret-moon.jpg"
        />
      </div> */}

      {/* Start main hero section */}
      <animated.div
        style={{
          background: '#011e81',
          position: 'absolute',
          width: '100%',
          height: orientation === 'portrait' ? '135vh' : '400vh',
          top: 0,
          transform: layer_1,
        }}
      >
        <Image
          quality={50}
          priority={true}
          layout="fill"
          objectFit="cover"
          objectPosition="50% 0%"
          alt="Night sky with stars"
          src="/assets/images/sky.png"
        />
      </animated.div>

      <HeroWrapper>
        <animated.div style={{ transform: layer_2 }}>
          <HeroInner>
            <BannerCopy>
              <h1>Your Custom Moon Map</h1>
              <h2>
                Create a custom moon phase art print that shows the moon phase
                exactly as it was on a specific date and location with a
                personalized message!
              </h2>

              {showPrice && (
                <Price>
                  Starting at $35 <RetailPrice>$40</RetailPrice>
                </Price>
              )}
              <Link href="/create" passHref>
                <LinkButton variant="primary" className="my-8">
                  Create Your Moon Map
                </LinkButton>
              </Link>
            </BannerCopy>
            <HeroImageWrapper>
              <Image
                priority={true}
                layout="responsive"
                width={1262}
                height={1263}
                alt="Moon"
                src="/assets/images/moon.png"
              />
            </HeroImageWrapper>
          </HeroInner>
        </animated.div>
      </HeroWrapper>

      <SubHeroWrapper>
        <SubHeroImageWrapper>
          <Image
            layout="responsive"
            quality={50}
            priority={true}
            width={1440}
            height={1125}
            alt="OverOurMoon poster on wall"
            src="/assets/images/hero-combined.png"
          />
        </SubHeroImageWrapper>
        <SubHeroBannerCopy>
          <h1>
            Capture that <span>Special</span> Moment
          </h1>
          <Link href="/create" passHref>
            <LinkButton variant="primary" className="my-8">
              Create Your Moon Map
            </LinkButton>
          </Link>
        </SubHeroBannerCopy>
      </SubHeroWrapper>

      <SubHeroMobileWrapper>
        <SubHeroMobileBanneryCopy>
          <h1>
            Capture that <span>Special</span> Moment
          </h1>
        </SubHeroMobileBanneryCopy>

        <MobileImageWrapper>
          <Image
            quality={100}
            priority={true}
            layout="fill"
            objectFit="contain"
            alt="Moon Map by OverOurMoon;"
            src="/assets/images/hero-poster-mobile.png"
          />
        </MobileImageWrapper>

        <ButtonWrapper>
          <Link href="/create" passHref>
            <LinkButton variant="primary" className="my-8">
              Create Your Moon Map
            </LinkButton>
          </Link>
        </ButtonWrapper>
      </SubHeroMobileWrapper>
    </>
  );
};
