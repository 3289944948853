import styled from 'styled-components';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  padding-top: 2.5rem;

  h2 {
    ${(props: any) => props.theme.fonts.secondary}
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    margin-top: 0;
  }

  ${media.lg`
    h2 {
      margin-bottom: 4rem;
    }
  `}
`;

export const ImageContainer = styled.div`
  margin-bottom: 1.2rem;

  > div {
    display: block;
    border-radius: 0.5rem;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
`;

export const Information = styled.div`
  text-align: center;

  h2 {
    ${(props: any) => props.theme.fonts.secondary}
    margin: 0;
  }

  h4 {
    margin: 1rem 0 0 0;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
  }

  ${media.lg` 
    text-align: left;

    h2 {
      text-align: left;
    }
  `}
`;
