export const PaintBrush = (): JSX.Element => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="40px"
    height="40px"
  >
    <path d="M 36.78125 4.03125 C 36.488281 4.0625 36.226563 4.222656 36.0625 4.46875 L 23.90625 22 C 23.824219 22.121094 23.773438 22.261719 23.75 22.40625 C 23.6875 22.742188 23.664063 23.078125 23.6875 23.40625 C 19.988281 23.796875 17.445313 25.207031 15.71875 27.21875 C 13.84375 29.40625 12.90625 32.175781 12.0625 34.9375 C 11.21875 37.699219 10.464844 40.46875 9.125 42.78125 C 7.785156 45.09375 5.9375 46.96875 2.6875 48.0625 C 2.238281 48.226563 1.972656 48.6875 2.046875 49.160156 C 2.125 49.632813 2.523438 49.984375 3 50 C 13.371094 50 20.929688 49.351563 26.09375 46.4375 C 31.058594 43.636719 33.507813 38.636719 33.6875 30.875 C 33.792969 30.863281 33.898438 30.863281 34 30.84375 C 34.292969 30.789063 34.542969 30.605469 34.6875 30.34375 L 47.84375 5.53125 C 48.101563 5.039063 47.914063 4.429688 47.421875 4.171875 C 46.929688 3.914063 46.320313 4.101563 46.0625 4.59375 L 33.25 28.8125 C 32.089844 28.851563 30.058594 28.226563 28.40625 27 C 27.507813 26.332031 26.730469 25.539063 26.25 24.78125 C 25.792969 24.058594 25.671875 23.429688 25.75 22.875 L 37.6875 5.625 C 37.925781 5.304688 37.953125 4.878906 37.757813 4.53125 C 37.558594 4.1875 37.175781 3.988281 36.78125 4.03125 Z M 24.3125 25.34375 C 24.398438 25.507813 24.464844 25.6875 24.5625 25.84375 C 25.21875 26.882813 26.164063 27.8125 27.21875 28.59375 C 28.601563 29.621094 30.167969 30.421875 31.6875 30.75 C 31.542969 38.148438 29.464844 42.222656 25.09375 44.6875 C 21.308594 46.824219 15.121094 47.550781 7.34375 47.78125 C 8.832031 46.636719 9.980469 45.273438 10.84375 43.78125 C 12.363281 41.160156 13.136719 38.25 13.96875 35.53125 C 14.800781 32.8125 15.710938 30.324219 17.25 28.53125 C 18.722656 26.8125 20.777344 25.621094 24.3125 25.34375 Z" />
  </svg>
);
