import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  ${tw`py-10 lg:py-28 bg-deep-blue text-moon-sand`}
`;

export const ButtonWrapper = styled.div`
  ${tw`grid grid-cols-12`}

  a {
    ${tw`col-start-1 col-span-12 justify-self-center`}
  }
`;

export const QuoteWrapper = styled.div`
  ${tw`grid grid-cols-12`}
  ${props => props.theme.fonts.testimonials}
`;

export const LeftQuote = styled.span`
  ${tw`col-start-2 col-span-10 lg:col-start-3 lg:col-span-1 xxl:col-start-4 xxl:text-center -mt-8 -mb-28`}
  font-size: 144px;
`;

export const RightQuote = styled.span`
  ${tw`col-start-9 col-span-3 xxl:col-start-8 self-end -mt-12 -mb-20 lg:-mb-0 lg:-mt-28 text-left lg:text-center`}
  font-size: 144px;
`;

export const Quote = styled.h2`
  ${tw`col-start-2 col-span-10 lg:col-start-4 lg:col-span-6 xxl:col-start-5 xxl:col-span-4 text-center`}
  font-size: 1rem;
  > span {
    font-size: 1.3rem;
  }
  font-weight: 400;
  ${media.lg`
    font-size: 1.375rem;

    > span {
      font-size: 2rem;
    }
  `}
`;

export const Name = styled.p`
  ${tw`col-start-5 col-span-4 lg:col-start-6 lg:col-span-2 mt-0 text-center`}
  font-family: 'IBM Plex Sans', sans serif;
  font-size: 1.3rem;

  ${media.lg`
    font-size: 1.5rem;
  `}
`;
