import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { LinkButton } from '@app/components/ui/Button';
import { ImageContainer, Information, Wrapper } from './styles';

export const Reasons: React.FC = () => {
  return (
    <Wrapper>
      <Container fluid>
        <Row justify="center">
          <Col>
            <h2>What&apos;s Your Special Moment?</h2>
          </Col>
        </Row>

        <Row justify="center">
          <Col lg={3.5} xxl={2.5} style={{ textAlign: 'center' }}>
            <ImageContainer>
              <LazyLoad height={500} offset={400} once>
                <Image
                  src="/assets/images/anniversary.jpeg"
                  alt="Moon Map by OverOurMoon;"
                  width={500}
                  height={500}
                  quality={50}
                  priority={true}
                />
              </LazyLoad>
            </ImageContainer>
            <Information>
              <h2>Anniversary</h2>
              <h4>
                Maybe it was the day you chose forever with your loved one.
              </h4>

              <Visible xs sm md>
                <Link href="/create" passHref>
                  <LinkButton
                    variant="primary"
                    style={{ margin: '2rem 0 4rem 0' }}
                  >
                    Create Your Moon Map
                  </LinkButton>
                </Link>
              </Visible>
            </Information>
          </Col>

          <Col lg={3.5} xxl={2.5} style={{ textAlign: 'center' }}>
            <ImageContainer>
              <LazyLoad height={320} offset={400} once>
                <Image
                  src="/assets/images/birthday.jpeg"
                  alt="Moon Map by OverOurMoon;"
                  width={500}
                  height={500}
                  quality={50}
                  priority={true}
                />
              </LazyLoad>
            </ImageContainer>
            <Information>
              <h2>Birthday</h2>
              <h4>
                Or the birthday of a special person who has brought love and joy
                to your life like a mother, father, sister, brother or friend.
                Perhaps it was the day your child was born.
              </h4>

              <Visible xs sm md>
                <Link href="/create" passHref>
                  <LinkButton
                    variant="primary"
                    style={{ margin: '2rem 0 4rem 0' }}
                  >
                    Create Your Moon Map
                  </LinkButton>
                </Link>
              </Visible>
            </Information>
          </Col>

          <Col lg={3.5} xxl={2.5} style={{ textAlign: 'center' }}>
            <ImageContainer>
              <LazyLoad height={320} offset={400} once>
                <Image
                  src="/assets/images/milestone.jpeg"
                  alt="Moon Map by OverOurMoon;"
                  width={500}
                  height={500}
                  quality={50}
                  priority={true}
                />
              </LazyLoad>
            </ImageContainer>
            <Information>
              <h2>Milestone</h2>
              <h4>
                Maybe someone you know achieved a meaningful milestone worth
                commemorating.
              </h4>

              <Visible xs sm md>
                <Link href="/create" passHref>
                  <LinkButton
                    variant="primary"
                    style={{ margin: '2rem 0 4rem 0' }}
                  >
                    Create Your Moon Map
                  </LinkButton>
                </Link>
              </Visible>
            </Information>
          </Col>
        </Row>
        <Row justify="center">
          <Col lg={3} style={{ textAlign: 'center' }}>
            <Visible lg xl xxl>
              <Link href="/create">
                <LinkButton variant="primary" style={{ margin: '4rem 0' }}>
                  Create Your Moon Map
                </LinkButton>
              </Link>
            </Visible>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Reasons;
