import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`grid grid-cols-12 lg:py-8 bg-moon-sand`}

  h2 {
    ${tw`text-night-sky`}
    ${props => props.theme.fonts.headline_1}
  }

  ul {
    ${tw`list-none`}
  }
`;

export const Header = styled.div`
  ${tw`col-start-1 lg:col-start-3 col-span-12 lg:col-span-4 xxl:col-start-4 xxl:col-span-3 text-center lg:text-left p-4 lg:p-0 `}

  > h2 {
    ${tw`text-5xl lg:text-7xl`}
  }
`;

export const ListWrapper = styled.div`
  ${tw`col-span-12 lg:col-start-8 lg:col-span-5 xxl:col-start-7 self-center`}
`;

export const List = styled.section`
  ${tw`grid grid-cols-12 md:grid-rows-6 md:grid-flow-col md:grid-cols-2 xl:grid-cols-3`}
`;

export const CheckmarkWrapper = styled.div`
  ${tw`col-start-4 col-span-1 lg:col-start-1 xl:col-start-4 my-2`}
`;

export const ListItem = styled.div`
  ${tw`col-start-5 col-span-7 lg:col-start-3 xl:col-start-6 my-2 pl-4 lg:pl-0`}
`;

export const Reason1 = styled.div`
  ${tw`col-start-1 col-span-6 lg:col-start-3 lg:col-span-2 xxl:col-start-5 xxl:col-span-1 text-center p-4 ml-6 lg:ml-0 mt-14 border-gray-200 border-solid border-0 border-r border-b lg:border-b-0`}
`;

export const Reason2 = styled.div`
  ${tw`col-start-7 col-span-6 lg:col-start-5 lg:col-span-2 xxl:col-start-6 xxl:col-span-1 text-center p-4 mr-6 lg:mr-0 mt-14 border-gray-200 border-solid border-0 lg:border-r border-b lg:border-b-0`}
`;

export const Reason3 = styled.div`
  ${tw`col-start-1 col-span-6 lg:col-start-7 lg:col-span-2 xxl:col-start-7 xxl:col-span-1 text-center p-4 ml-6 lg:ml-0 lg:mt-14 border-gray-200 border-solid border-0 border-r`}
`;

export const Reason4 = styled.div`
  ${tw`col-start-7 col-span-6 lg:col-start-9 lg:col-span-2 xxl:col-start-8 xxl:col-span-1 text-center p-4 mr-6 lg:mr-0 lg:mt-14`}
`;

export const ButtonWrapper = styled.div`
  ${tw`col-start-1 col-span-12 text-center`}
`;
