import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  ${tw`grid grid-cols-12 bg-night-sky text-moon-sand`}

  img {
    ${tw`cursor-default`}
  }
`;

export const Heading = styled.div`
  ${tw`col-start-2 col-span-10 text-center`}

  h2 {
    ${props => props.theme.fonts.headline_1}
    font-size: 3rem;
    ${tw`mb-5`}

    ${media.lg`
      ${props => props.theme.fonts.headline_1}
    `}
  }

  h4 {
    ${props => props.theme.fonts.subhead}
    ${tw`mt-0 mb-10`}
  }

  span {
    ${tw`italic`}
  }
`;

export const GalleryWrapper = styled.div`
  ${tw`col-start-3 col-span-8 md:col-start-2 md:col-span-10 xl:col-start-3 xl:col-span-8 xxl:col-start-4 xxl:col-span-6 grid-cols-12 gap-y-3 lg:gap-y-4 md:gap-x-3 lg:gap-x-4 xxxl:gap-x-0 justify-items-center hidden md:grid`}
`;

export const MobileGalleryWrapper = styled.div`
  ${tw`col-start-1 col-span-12 md:hidden`}

  .slider-wrapper {
    ${tw`px-4`}
  }

  .slide {
    ${tw`px-2 pb-14 bg-night-sky`}
  }

  .control-dots {
    ${tw`mt-7`}
  }

  .carousel .control-dots > li {
    ${tw`mx-1 shadow-none`}
  }
`;

export const PhotoWrapper = styled.div`
  ${tw`col-span-12 md:col-span-4`}

  img {
    ${tw`rounded-xl`}}
  }
`;

export const LinkWrapper = styled.div`
  ${tw`col-start-2 col-span-10 text-center mt-10 mb-16 z-10`}

  ${media.lg`
    box-shadow: 0 -70px 30px 20px ${props => props.theme.colors['night-sky']};
  `}
`;
