import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

export const HeroWrapper = styled.div`
  > div:first-child {
    ${tw`col-start-1 col-span-12`}
  }
`;

export const HeroInner = styled.div`
  ${tw`grid grid-cols-12 items-center`}
`;

export const BannerCopy = styled.div`
  ${tw`col-start-1 col-span-12 md:col-start-3 md:col-span-8 lg:col-start-3 lg:col-span-4 xxl:col-start-3 xxl:col-span-3 xxxl:col-start-4 xxxl:col-span-2 xl:pr-10 mt-0 md:mt-0 text-center lg:text-left text-moon-sand z-10`}

  h1 {
    ${props => props.theme.fonts.headline_2}
  }

  h2 {
    ${props => props.theme.fonts.body}
  }

  span {
    font-style: italic;
  }
`;

export const HeroImageWrapper = styled.div`
  ${tw`col-start-1 col-span-12 md:col-start-3 md:col-span-8 lg:col-span-6 xl:col-span-5 z-10`}
`;

export const SubHeroWrapper = styled.div`
  ${tw`hidden md:grid relative -top-20 lg:-top-0 xl:-top-20`}
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
`;

export const SubHeroImageWrapper = styled.div`
  grid-column: 1 / 13;
  grid-row: 1 / 13;
`;

export const SubHeroBannerCopy = styled.div`
  ${tw`z-10 text-moon-sand`}
  grid-column: 3 / 6;
  grid-row: 4 / 8;

  h1 {
    ${props => props.theme.fonts.headline_2}
  }

  span {
    font-style: italic;
  }

  ${media.md`
    h1 {
      font-size: 2.2rem;
      ${tw`mt-12`}
    }

    grid-column: 3 / 7;
    grid-row: 4 / 7;
  `}

  ${media.lg`
    grid-row: 6 / 10;
    grid-column: 3 / 7;

    h1 {
      ${props => props.theme.fonts.headline_2}
    }
  `}

  ${media.xl`
    grid-row: 7 / 11;
  `}

  ${media.xxl`
    grid-column: 4 /7;
  `}
`;

export const SubHeroMobileWrapper = styled.div`
  ${tw`relative -top-72 xs:-top-56 md:-top-60 grid md:hidden`}

  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
`;

export const SubHeroMobileBanneryCopy = styled.div`
  ${tw`z-10 text-center text-moon-sand`}
  grid-column: 2 / 12;
  grid-row: 4 / 6;

  h1 {
    ${tw`mt-5`}
    ${props => props.theme.fonts.headline_4}
  }

  span {
    font-style: italic;
  }

  ${media.xs`
    h1 {
      ${tw`mt-0`}
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${tw`z-10 text-center mt-8`}
  grid-column: 1 / 13;
  grid-row: 10 / 12;

  ${media.xs`
    ${tw`mt-6`}
    grid-column: 1 / 13;
    grid-row: 11 / 13;
  `}
`;

export const MobileImageWrapper = styled.div`
  height: 858px;
  grid-column: 1 / 13;
  grid-row: 1 / 13;

  ${media.sm`
    height: 1000px;
  `}

  ${media.md`
    height: 1900px;
  `}
`;

export const Price = styled.div`
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 0.25rem;
  text-align: center;

  ${media.lg`
    text-align: left;
    margin-bottom: 2rem;
  `}
`;

export const RetailPrice = styled.span`
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 400;
  margin-left: 4px;
  color: #e44444;
  text-decoration: line-through;
`;
