import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

interface WrapperProps {
  index: number;
}

interface OrientationProps {
  orientation?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${tw`text-night-sky`}

  p {
    ${props => props.theme.fonts.body}
  }

  span {
    ${tw`italic`}
  }

  .control-dots {
    ${tw`mb-7 mt-0`}
  }

  .carousel .control-dots > li {
    background-color: ${props =>
      props.index === 0
        ? props.theme.colors['night-sky']
        : props.theme.colors['moon-sand']};

    ${tw`mx-1 shadow-none`}
  }
`;

export const SlideWrapper = styled.div`
  ${tw`grid h-screen`}
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
`;

export const SlideTitle = styled.div<OrientationProps>`
  ${tw`col-start-2 col-span-10 text-center lg:pb-14`}

  grid-row: 3 / 4;

  h2 {
    ${tw`mb-3 md:mb-5 pt-3 lg:pt-0`}
    ${props => props.theme.fonts.headline_4}
    font-size: 1.2rem;
  }

  > p {
    ${tw`my-2`}
    font-size: 0.9rem;
  }

  ${media.xs`
    h2 {
      ${props => props.theme.fonts.headline_4}
    }

    > p {
      ${props => props.theme.fonts.body}
    }
  `}

  ${media.xs`
    grid-row: 2 / 3;
  `}

  ${media.lg`
    grid-row: 3 / 4;
  `}

  ${media.xxl`
    grid-row: 4 / 5;
  `}

  ${props =>
    props.orientation === 'landscape' &&
    'grid-column: 1 / 5; grid-row: 4 / 9 !important; margin-left: 1rem;'}
`;

export const SlideGif = styled.div<OrientationProps>`
  ${tw`col-start-5 col-span-4 lg:col-start-4 lg:col-end-7 place-self-center`}

  grid-row: 4 / 7;

  ${media.xs`
    grid-row: 3 / 8;
  `}

  ${media.lg`
    grid-row: 4 / 11;
  `}

  ${media.xxl`
    grid-row: 5 / 9;
  `}

  ${props =>
    props.orientation === 'landscape' &&
    'grid-column: 6 / 8; grid-row: 6 / 9 !important;'}
`;

export const SlideCopy = styled.div<OrientationProps>`
  ${tw`col-start-2 col-end-12 lg:col-start-7 lg:col-end-10 text-center pt-3 sm:pt-0 lg:text-left lg:pl-8 place-self-center`}

  grid-row: 8 / 10;

  h3 {
    ${props => props.theme.fonts.headline_3}
    ${tw`my-2`}
    font-size: 1.2rem;

    ${media.xs`
    ${tw`mt-5 mb-0`}
      font-size: 2rem;
    `}

    ${media.lg`
      font-size: 2.875rem;
    `}
  }

  > p {
    ${tw`mt-2`}
    font-size: 0.9rem;

    ${media.xs`
      ${props => props.theme.fonts.body}
    `}
  }

  ${media.lg`
    grid-row: 4 / 11;
  `}

  ${media.xxl`
    grid-row: 5 / 9;
  `}

  ${props =>
    props.orientation === 'landscape' &&
    'grid-column: 9 / 13; margin-right: 2rem;'}
`;

export const ButtonWrapper = styled.div<OrientationProps>`
  ${tw`col-span-12`}

  grid-row: 11 / 12;

  ${media.xxl`
    grid-row: 9 / 10;
  `}

  ${props => props.orientation === 'landscape' && 'grid-row: 10 / 11;'}
`;

export const TopSpacer = styled.div`
  grid-row: 1 / 2;
  height: calc(100vh * 0.1);
`;

export const BottomSpacer = styled.div`
  grid-row: 12 / 13;
  height: calc(100vh * 0.08);

  ${media.lg`
    height: calc(100vh * 0.1);
  `}
`;
