import * as React from 'react';
import {
  Wrapper,
  QuoteWrapper,
  LeftQuote,
  RightQuote,
  Quote,
  Name,
} from './TestimonialRedesign.styles';

export const TestimonialRedesign: React.FC = () => {
  return (
    <Wrapper>
      <QuoteWrapper>
        <LeftQuote>&ldquo;</LeftQuote>
        <Quote>
          <span>
            My husband and I like to find unique gifts for our anniversary every
            year.
          </span>
          <br />
          This year was our 10 year anniversary and I wanted it to be something
          extra special. I&apos;m so happy I found OverOurMoon because when I
          gave him the Moon Phase Map he was blown away by the level of detail
          in the print and moved by the message I wrote him. We now have it
          displayed above our fireplace and it&apos;s become quite the
          conversation piece. I truly couldn&apos;t be happier.
        </Quote>
        <Name>- Jessica D.</Name>
        <RightQuote>&rdquo;</RightQuote>
      </QuoteWrapper>
    </Wrapper>
  );
};
