import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${props => props.theme.bgColors['light-100']};
  padding 2.5rem 0;

  h2 {
    ${(props: any) => props.theme.fonts.secondary}
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
    padding: 0 1.5rem;
  }

  p {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
  }

  span {
    font-style: italic;
  }

  section {
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: center;

    h2 {
      ${(props: any) => props.theme.fonts.secondary}
      font-size: 2rem;
      text-align: center;
      margin: 0;
    }

    h4 {
      font-size: 1.1rem;
      margin: 0;
      margin-top: 1rem;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      width: 83.33333%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  background: ${props => props.theme.bgColors['light-blue-200']};
  box-shadow: 0 6px 22px #e6eaee;
  border-radius: 50%;
  padding: 1.2rem;

  img {
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
`;
