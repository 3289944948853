import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import { animated, useSpring, config } from '@react-spring/web';
import { isOnScreen } from '@app/lib/utils';
import { useRouter } from 'next/router';
import { LinkButton } from '@app/components/ui/Button';
import {
  Wrapper,
  Heading,
  ReviewsWrapper,
  Review,
  GoogleReviewPercent,
  GoogleReviewLogo,
  Stars,
  Verified,
  Col_1,
  Col_2,
  Col_3,
  LinkWrapper,
} from './Reviews.styles';

/* Icons */
const StarIcon = require('./icons/star.svg');

const Percent = () => {
  const [toggle, set] = useState(false);
  const { number } = useSpring({
    from: { number: 70 },
    number: toggle ? 99 : 70,
    delay: 200,
    config: config.molasses,
  });

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.querySelector &&
        document.querySelector('.number-animation-trigger')
      ) {
        const elem = document.querySelector('.number-animation-trigger');
        isOnScreen(elem) && set(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <animated.div className="number-animation-trigger">
      {number.to(n => n.toFixed(0))}
    </animated.div>
  );
};

export const Reviews: React.FC = () => {
  const router = useRouter();

  let reviewsPage = false;

  if (['/reviews'].some(page => router.pathname.indexOf(page) > -1)) {
    reviewsPage = true;
  }

  return (
    <>
      <Wrapper>
        {reviewsPage ? (
          <Heading>Verified Reviews</Heading>
        ) : (
          <Heading>
            Don&apos;t <span>Just</span> Take Our Word For It
          </Heading>
        )}

        <GoogleReviewPercent>
          <div>
            <Percent />%
          </div>
          <p>
            of reviewers recommend <br />
            <span>OverOurMoon.com</span>
          </p>
        </GoogleReviewPercent>
        <GoogleReviewLogo>
          <LazyLoad height={74} offset={400} once>
            <Image
              src="/assets/images/google_reviews_logo_white.png"
              alt="Google Reviews Logo"
              width={181}
              height={74}
              quality={50}
            />
          </LazyLoad>
        </GoogleReviewLogo>

        <ReviewsWrapper>
          <Col_1>
            <Review>
              <h2>Ashley M.</h2>
              <Stars>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
              </Stars>
              <Verified>Verified Buyer</Verified>
              <p>
                Amazing customer service! I can&apos;t say thank you enough for
                a lovely, quality gift that my husband absolutely LOVES and
                stellar customer service. You made our day extra special and I
                will be recommending OverOurMoon to everyone I know!
              </p>
            </Review>
            <Review>
              <h2>Christine R.</h2>
              <Stars>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
              </Stars>
              <Verified>Verified Buyer</Verified>
              <p>
                I&apos;m not much for writing reviews, but I couldn&apos;t help
                but write about my experience with OverOurMoon. I bought this
                for my brother who&apos;s son passed away 2 years ago. I gave
                him the moon on the night that he became a father. We were both
                so emotional. Needless to say we both cried. He said this was
                such a thoughtful gift and a beautiful way to honor his son. I
                would definitely recommend OverOurMoon to everyone. The entire
                experience, from how easy it was to use the website to the
                customer service was wonderful. Also it shipped a lot faster
                than I thought it would and received it in perfect condition.
              </p>
            </Review>
          </Col_1>
          <Col_2>
            <Review>
              <h2>Mary G.</h2>
              <Stars>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
              </Stars>
              <Verified>Verified Buyer</Verified>
              <p>
                Whenever I see an AD on Facebook or Pinterest I get super
                nervous but I took a chance and I&apos;M SO GLAD I ORDERED!
                I&apos;m super pleased with the end result and the quality. It
                looks so much better in person! I bought this as a 15th wedding
                anniversary for my husband and I. Fulfillment and shipping was
                fast and they sent me email updates every step of the way. The
                product was EXACTLY as advertised. Amazing follow-up and
                customer service. I HIGHLY recommend this for anyone looking for
                a unique gift to commemorate a special moment in their or
                someone else&apos;s life.
              </p>
            </Review>
            <Review>
              <h2>Joyce J.</h2>
              <Stars>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
              </Stars>
              <Verified>Verified Buyer</Verified>
              <p>
                The quality of the moon map is beautiful and the style is so
                simple yet powerful. I love that you can see every little detail
                of the moon. I also appreciate that the company included a
                warning about the possibility of the ink smudging (due to the
                fact that it&apos;s matte archival paper) so that I can be sure
                to handle it carefully when unpackaging it. I can&apos;t wait to
                frame it and hang it up it for everyone to see.
              </p>
            </Review>
          </Col_2>
          <Col_3>
            <Review>
              <h2>Susan A.</h2>
              <Stars>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
              </Stars>
              <Verified>Verified Buyer</Verified>
              <p>
                I purchased this as a gift for my partner. The ordering process
                is very easy and you will see a mock up of the finished product.
                Additionally you have 24 hours AFTER you place your order to
                edit it or correct anything you may have noticed after you hit
                &ldquo;submit.&rdquo; I received it roughly 10 days after
                purchase, including processing and shipping time, which I think
                is very fast. When I opened it to check the finished product, it
                brought tears to my eyes. My partner is going to love it and I
                think I&apos;ll have to get one for myself as well. Thank you so
                much!
              </p>
            </Review>
            <Review>
              <h2>Greg Z.</h2>
              <Stars>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
                <li>
                  <img src={StarIcon} alt="Star" />
                </li>
              </Stars>
              <Verified>Verified Buyer</Verified>
              <p>
                The quality of this product is remarkable. It turned out better
                than expected. It was well worth the money and I will definitely
                be buying more. It&apos;s very unique and the perfect gift for
                the hard to buy for people in my life.
              </p>
            </Review>
          </Col_3>
        </ReviewsWrapper>

        <LinkWrapper>
          <Link href="/reviews" passHref>
            <LinkButton variant="primary" className="my-10">
              Read More Reviews
            </LinkButton>
          </Link>
        </LinkWrapper>
      </Wrapper>
    </>
  );
};

export default Reviews;
