import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import { LinkButton } from '@app/components/ui/Button';
import {
  Wrapper,
  Heading,
  ImageWrapper,
  TextWrapper,
  ImageWrapperAlt,
  TextWrapperAlt,
} from './Reasons.styles';

export const Reasons: React.FC = () => {
  return (
    <>
      <Wrapper>
        <Heading>
          What&rsquo;s <span>Your</span> Special Moment?
        </Heading>

        <ImageWrapper className="order-1">
          <LazyLoad height={500} offset={1000} once>
            <Image
              src="/assets/images/anniversary.jpeg"
              alt="Moon Map by OverOurMoon;"
              width={500}
              height={500}
              quality={50}
            />
          </LazyLoad>
        </ImageWrapper>
        <TextWrapper className="order-2">
          <h3>Anniversary</h3>
          <p>Maybe it was the day you chose forever with your loved one.</p>
          <Link href="/create" passHref>
            <LinkButton variant="primary">Create Your Moon Map</LinkButton>
          </Link>
        </TextWrapper>

        <ImageWrapperAlt className="order-3 lg:order-4">
          <LazyLoad height={500} offset={1000} once>
            <Image
              src="/assets/images/birthday.jpeg"
              alt="Moon Map by OverOurMoon;"
              width={500}
              height={500}
              quality={50}
            />
          </LazyLoad>
        </ImageWrapperAlt>
        <TextWrapperAlt className="order-4 lg:order-3">
          <h3>Birthday</h3>
          <p>
            Or the birthday of a special person who has brought love and joy to
            your life like a mother, father, sister, brother or friend. Perhaps
            it was the day your child was born.
          </p>
          <Link href="/create" passHref>
            <LinkButton variant="primary">Create Your Moon Map</LinkButton>
          </Link>
        </TextWrapperAlt>

        <ImageWrapper className="order-5">
          <LazyLoad height={500} offset={1000} once>
            <Image
              src="/assets/images/milestone.jpeg"
              alt="Moon Map by OverOurMoon;"
              width={500}
              height={500}
              quality={50}
            />
          </LazyLoad>
        </ImageWrapper>
        <TextWrapper className="order-6">
          <h3>Milestone</h3>
          <p>
            Maybe someone you know achieved a meaningful milestone worth
            commemorating.
          </p>
          <Link href="/create" passHref>
            <LinkButton variant="primary">Create Your Moon Map</LinkButton>
          </Link>
        </TextWrapper>
      </Wrapper>
    </>
  );
};

export default Reasons;
