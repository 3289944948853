import Image from 'next/image';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import { LinkButton } from '@app/components/ui/Button';
import { PHOTO_SET } from './photos';
import {
  Wrapper,
  GalleryWrapper,
  PhotoWrapper,
  Heading,
  LinkWrapper,
} from './styles';

export const Inspiration: React.FC = () => (
  <Wrapper>
    <Heading>
      <h2>One of a Kind Custom Piece of Art!</h2>
      <h4>It will be the perfect sentimental addition to any home.</h4>
    </Heading>

    <GalleryWrapper>
      {PHOTO_SET.map((image, i) => (
        <PhotoWrapper key={i}>
          <LazyLoad height={300} offset={200} once>
            <Image
              src={image.src}
              alt={'OverOurMoon; Poster'}
              width={300}
              height={300}
              quality={50}
            />
          </LazyLoad>
        </PhotoWrapper>
      ))}
    </GalleryWrapper>

    <LinkWrapper>
      <Link href="/create" passHref>
        <LinkButton variant="primary" style={{ margin: '3em 0' }}>
          Create Your Moon Map
        </LinkButton>
      </Link>
    </LinkWrapper>
  </Wrapper>
);

export default Inspiration;
